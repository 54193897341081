.chart__bubble {
  height: 100%;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.fill {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  background-repeat: no-repeat;
}

.bubble-legend {
  width: 0px;
  height: 0px;
}