.App {
  text-align: center;
}

.calculatorPage {
  text-align: center;
}

.costsPage {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fourohfourimagestyle {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  display: flex;
  margin: auto;
  max-width: 460px;
}

.blogimage {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  display: flex;
  margin: auto;
  max-width: 600px;
}